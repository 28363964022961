define('pzu-op/tests/components/af-pikaday-input.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components/af-pikaday-input.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'components/af-pikaday-input.js should pass jshint.');
  });

});