define('pzu-op/components/ow-rate', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagNme: "ul",
    rate: 0,
    hoverRate: 0,

    rates: Ember['default'].computed("maxRate", "rate", "hoverRate", function () {
      var rate = this.get("rate");
      var hoverRate = this.get("hoverRate");
      var RateItem = Ember['default'].Object.extend({
        item: null,
        highlightClass: Ember['default'].computed("item", function () {
          if (hoverRate > 0) {
            return this.get("item") <= hoverRate ? "ow-rate-highlight" : "";
          } else {
            return this.get("item") <= rate ? "ow-rate-highlight" : "";
          }
        })
      });

      var maxRate = this.get("maxRate");
      var rates = Ember['default'].A();
      for (var i = 1; i <= maxRate; i++) {
        rates.push(RateItem.create({
          item: i
        }));
      }

      return rates;
    }),

    actions: {
      rateChanged: function rateChanged(item) {
        if (this.get("enabled")) {
          this.set("rate", item.item);
        }
      },

      mouseEnter: function mouseEnter(item) {
        if (this.get("enabled")) {
          this.set("hoverRate", item.item);
        }
      },

      mouseLeave: function mouseLeave() {
        if (this.get("enabled")) {
          this.set("hoverRate", 0);
        }
      }
    }
  });

});