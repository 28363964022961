define('pzu-op/tests/unit/utils/is-in-viewport-test', ['pzu-op/utils/is-in-viewport', 'qunit'], function (isInViewport, qunit) {

  'use strict';

  qunit.module('Unit | Utility | is in viewport');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = isInViewport['default']();
    assert.ok(result);
  });

});