define('pzu-op/routes/application', ['exports', 'ember', 'pzu-op/config/environment'], function (exports, Ember, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    store: Ember['default'].inject.service(),
    auth: Ember['default'].inject.service(),

    error: null,

    init: function init() {
      this._super();
      this.store.findAll('payu-transaction-status');
      this.registerErrorHandlers();
    },

    actions: {
      refreshAllApp: function refreshAllApp(waitingTransition) {
        if (waitingTransition) {
          if (typeof waitingTransition === 'string') {
            this.transitionTo(waitingTransition);
          } else {
            waitingTransition.retry();
          }
        } else {
          this.transitionTo('/');
        }
        this.set('controller.errorId', null);
        this.refresh();
      },

      transitToIndex: function transitToIndex() {
        this.transitionTo('/');
        this.set('controller.errorId', null);
        this.refresh();
      },

      closeError: function closeError() {
        this.set('controller.errorId', null);
        this.send('refreshAllApp');
      },

      error: function error(_error, transition, originRoute) {
        var errorIdFromBackend = this.extractCodeFromError(_error);
        if (errorIdFromBackend === null) {
          this.logErrorToBackend(_error).then(function (errorResponse) {
            originRoute.intermediateTransitionTo('error', errorResponse);
          });
        } else {
          originRoute.intermediateTransitionTo('error', _error);
        }
      }
    },

    registerErrorHandlers: function registerErrorHandlers() {
      var _this2 = this;

      // Trap exceptions from within Ember run loop
      Ember['default'].onerror = this.errorHandler(this);

      // Trap exceptions from within jQuery ajax.
      Ember['default'].$(document).ajaxError(function (event, jqxhr) {
        var errorFromBackend = JSON.parse(jqxhr.responseText);
        _this2.errorHandler(_this2)(errorFromBackend);
      });
    },

    logErrorToBackend: function logErrorToBackend(error) {
      return Ember['default'].$.ajax(config['default'].apiPath + '/log', {
        global: false,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          id: error.id,
          name: error.name,
          message: error.message,
          stackTrace: error.stack,
          url: document.location.href
        }),
        error: function error() {
          throw new Error('Exception while submitting error log.');
        }
      });
    },

    errorHandler: function errorHandler(route) {
      var _this = this;
      return function onError(error) {
        var errorIdFromBackend = _this.extractCodeFromError(error);

        var currentErrorId = route.get('controller.errorId');

        var isCurrentAndBackendErrorIdSet = !Ember['default'].isNone(currentErrorId) && !Ember['default'].isNone(errorIdFromBackend);
        var isCurrentAndBackendIdsEqual = currentErrorId === errorIdFromBackend;
        var isAlreadyReported = isCurrentAndBackendErrorIdSet && isCurrentAndBackendIdsEqual;

        if (isAlreadyReported) {
          return;
        }

        if (!Ember['default'].isNone(errorIdFromBackend)) {
          if (errorIdFromBackend === "ValidationError") {
            var source = error.errors[0].source.pointer;
            var message = error.errors[0].detail;
            route.set('controller.errorId', source + " " + message);

            return;
          }

          route.set('controller.errorId', errorIdFromBackend);
          return;
        }

        _this.logErrorToBackend(error).then(function (errorResponse) {
          var errorIdFromBackend = _this.extractCodeFromError(errorResponse);
          if (!Ember['default'].isNone(errorIdFromBackend)) {
            route.set('controller.errorId', errorIdFromBackend);
          } else {
            throw new Error('Unreadable error code.');
          }
        });
      };
    },

    isValidError: function isValidError(error) {
      if (Ember['default'].isNone(error)) {
        return false;
      }

      return !Ember['default'].isNone(error.errors) && error.errors.length > 0;
    },

    extractCodeFromError: function extractCodeFromError(error) {
      if (this.isValidError(error)) {
        return error.errors[0].id;
      }

      return null;
    }
  });

});