define('pzu-op/components/ow-buttons', ['exports', 'ember', 'pzu-op/components/ow-form'], function (exports, Ember, OwForm) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    submitLabel: 'Save',
    submitClass: 'btn btn-primary',
    cancelLabel: 'Cancel',
    cancelClass: 'btn btn-default',

    form: Ember['default'].computed(function () {
      return this.nearestOfType(OwForm['default']);
    }),

    isInvalid: Ember['default'].computed.alias('form.model.isInvalid'),

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });

});