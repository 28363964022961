define('pzu-op/models/user-account', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, Ember, DS, EmberValidations) {

  'use strict';

  exports['default'] = DS['default'].Model.extend(EmberValidations['default'], {
    firstName: DS['default'].attr('string'),
    lastName: DS['default'].attr('string'),
    isActive: DS['default'].attr('boolean'),
    emailAddress: DS['default'].attr('string'),

    locked: Ember['default'].computed.not('active'),

    fullName: Ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    name: Ember['default'].computed.alias('fullName')
  });

});