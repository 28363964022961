define('pzu-op/components/ow-typeahead', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['placeholder'],
    hint: true,
    highlight: true,
    minLength: 1,
    readOnly: false,
    placeholder: null,

    value: null,

    delay: 150,

    _valueOnChange: Ember['default'].observer('value', function () {
      this.$().typeahead('val', this.get('value'));
    }),

    dataSetAdapter: function dataSetAdapter() {
      var _this = this;

      return function (query, synchronicCall, asynchronicCall) {
        Ember['default'].run.debounce(_this, _this.sentRefresh, [query, asynchronicCall], _this.get('delay'));
      };
    },

    sentRefresh: function sentRefresh(_ref) {
      var _ref2 = _slicedToArray(_ref, 2);

      var text = _ref2[0];
      var callbackFn = _ref2[1];

      this.sendAction('refreshAction', text, callbackFn);
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var configurationParams = {
        hint: this.get('hint'),
        highlight: this.get('highlight'),
        minLength: this.get('minLength')
      };

      var dataSet = {
        source: this.dataSetAdapter()
      };

      var element = this.$();
      element.typeahead(configurationParams, dataSet);
      element.typeahead('val', this.get('value'));
      element.prop('disabled', this.get('readOnly'));

      element.on('typeahead:change', function () {
        _this2.set('value', _this2.$().typeahead('val'));
        _this2.sendAction('onChanged', _this2.$().typeahead('val'));
      });

      element.on('keypress', function (e) {
        if (e.which === 13) {
          _this2.set('value', _this2.$().typeahead('val'));
          _this2.sendAction('onEnter', _this2.$().typeahead('val'));
        }
      });

      element.on('typeahead:select', function (e, selected) {
        _this2.set('value', selected);
        _this2.sendAction('onSelect', _this2.$().typeahead('val'));
      });
    }
  });

});