define('pzu-op/controllers/login', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    login: null,
    password: null,
    errorMessage: null,

    waitingTransition: null,
    fromUrl: null,

    auth: Ember['default'].inject.service(),

    reset: function reset() {
      this.setProperties({
        login: null,
        password: null,
        errorMessage: null
      });
    },

    actions: {
      login: function login() {
        var _this = this;

        var login = this.get('login');
        var password = this.get('password');

        this.get('auth').login(login, password).then(function (success) {
          if (success) {
            _this.set('errorMessage', null);
            var waitingTransition = _this.get('waitingTransition');
            if (waitingTransition) {
              _this.set('waitingTransition', null);
            }
            var fromUrl = _this.get('fromUrl');
            if (fromUrl) {
              _this.set('fromUrl', null);
            }
            _this.send('refreshAllApp', waitingTransition || fromUrl);
          } else {
            _this.set('errorMessage', "Błąd logowania");
          }
        });
      },
      forgottenPassword: function forgottenPassword() {
        var login = this.get('login');

        if (!login) {
          this.set('errorMessage', 'Podaj adres e-mail');
          return;
        }

        this.set('login', null);
        this.set('password', null);

        this.get('auth').forgottenPassword(login);
      }
    }
  });

});