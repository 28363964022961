define('pzu-op/tests/integration/components/tags/tags-list-row-test.jshint', function () {

  'use strict';

  QUnit.module('JSHint - integration/components/tags/tags-list-row-test.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'integration/components/tags/tags-list-row-test.js should pass jshint.');
  });

});