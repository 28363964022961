define('pzu-op/mixins/anonymous-route', ['exports', 'ember', 'pzu-op/services/auth'], function (exports, Ember, auth) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    auth: Ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super();
      return this.get('auth.authStatus').then(function (authStatus) {
        if (authStatus === auth.AuthStatus.LOGGED_IN) {
          _this.transitionTo('index');
        } else if (authStatus === auth.AuthStatus.PASSWORD_CHANGE_REQUIRED) {
          _this.transitionTo('change-password');
        }
      });
    }
  });

});