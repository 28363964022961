define('pzu-op/initializers/csrf', ['exports', 'ember', 'pzu-op/config/environment'], function (exports, Ember, config) {

  'use strict';

  exports['default'] = {
    name: "csrf",

    initialize: function initialize() {
      var CSRF_HEADER_NAME = "X-Csrf-Token";
      var CSRF_COOKIE_NAME = "ApiCsrfToken";

      function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) {
          return parts.pop().split(";").shift();
        }
        return null;
      }

      Ember['default'].$.ajaxPrefilter(function (options, originalOptions, xhr) {
        if (options.type !== 'GET') {
          var csrfToken = getCookie(CSRF_COOKIE_NAME);
          xhr.setRequestHeader(CSRF_HEADER_NAME, csrfToken);
        }
      });

      if (getCookie(CSRF_COOKIE_NAME) === null) {
        Ember['default'].$.get(config['default'].apiPath + "/no-content");
      }
    }
  };

});