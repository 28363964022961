define('pzu-op/components/af-pikaday-input', ['exports', 'pzu-op/components/pikaday-input', 'moment'], function (exports, pikadayInput, moment) {

  'use strict';

  exports['default'] = pikadayInput['default'].extend({

    setPikadayDate: function setPikadayDate() {
      if (this.get('value')) {
        this.get('pikaday').setDate(moment['default'](this.get('value'), this.get('format')).toDate(), true);
      } else {
        this.get('pikaday').setDate(null);
      }
    },

    userSelectedDate: function userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = moment['default'].utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.set('value', moment['default'](selectedDate).format(this.get('format')));
    }
  });

});