define('pzu-op/helpers/ago', ['exports', 'ember', 'pzu-op/helpers/moment-from-now'], function (exports, Ember, Helper) {

  'use strict';

  exports['default'] = Helper['default'].extend({
    compute: function compute() {
      Ember['default'].deprecate('ember-moment: `ago` helper has been renamed to `moment-from-now`');
      return this._super.apply(this, arguments);
    }
  });

});