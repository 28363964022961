define('pzu-op/mixins/tag-autocomplete', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    store: Ember['default'].inject.service(),

    _restrictedTokenNames: Ember['default'].computed.mapBy('tokens', 'name'),

    actions: {
      autocomplete: function autocomplete(text, callbackFn) {
        var _this = this;

        this.get('store').query('tag', { 'query[name]': text }).then(function (result) {
          return result.mapBy('name');
        }).then(function (result) {
          return result.filter(function (tagName) {
            return !_this.get('_restrictedTokenNames').contains(tagName);
          });
        }).then(function (resolved) {
          callbackFn(resolved);
        });
      }
    }
  });

});