define('pzu-op/components/ow-table', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    showCheckedAll: false,
    checkedAll: false,
    columns: Ember['default'].A(),
    rows: Ember['default'].A(),
    orderBy: null,
    desc: false,
    classes: 'table',
    hasMoreRows: false,

    inMemory: true,

    _rows: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('inMemory')) {
        this._rows = Ember['default'].computed('rows', 'orderBy', 'desc', 'size', function () {
          var orderBy = this.get('orderBy');
          var rows = this.get('rows');
          var size = this.get('size');
          if (orderBy) {
            rows = rows.sortBy(orderBy);
          }
          if (this.get('desc')) {
            rows = rows.reverseObjects();
          }
          if (rows.length > size) {
            rows = rows.slice(0, size);
          }

          return rows;
        });
      } else {
        this._rows = Ember['default'].computed.alias('rows');
      }
    },

    _wrappedColumns: Ember['default'].computed('columns', 'orderBy', 'desc', function () {
      var orderBy = this.get('orderBy');
      var desc = this.get('desc');

      return this.get('columns').map(function (column) {
        var classes = null;
        if (column.get('property') === orderBy) {
          classes = desc ? 'sort_desc' : 'sort_asc';
        }
        return Ember['default'].ObjectProxy.create({
          content: column,
          classes: classes
        });
      });
    }),

    _checkedAllObserver: Ember['default'].observer('checkedAll', function () {
      var checked = this.get('checkedAll');
      this.get('rows').forEach(function (row) {
        if (row.get('checked') !== undefined) {
          row.set('checked', checked);
        }
      });
    }),

    actions: {
      sort: function sort(column) {
        var columnProperty = column.get('property');
        if (this.get('orderBy') === columnProperty) {
          this.toggleProperty('desc');
        } else {
          this.set('orderBy', columnProperty);
          this.set('desc', false);
        }
      },

      loadMoreRows: function loadMoreRows() {
        if (this.get('hasMoreRows')) {
          if (this.get('inMemory')) {
            this.incrementProperty('size', 20);
          } else {
            this.sendAction();
          }
        }
      }
    }
  });

});