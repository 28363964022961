define('pzu-op/components/ow-input-group', ['exports', 'ember', 'pzu-op/components/ow-group'], function (exports, Ember, OwGroup) {

  'use strict';

  exports['default'] = OwGroup['default'].extend({
    //export default Ember.Component.extend({

    delayedValue: Ember['default'].computed.reads('value'),
    delay: 0,

    onDelayedValueChange: Ember['default'].observer('delayedValue', function () {
      Ember['default'].run.debounce(this, 'handleDelayedValueChange', this.get('delay'));
    }),

    onValueChange: Ember['default'].observer('value', function () {
      this.set('delayedValue', this.get('value'));
    }),

    handleDelayedValueChange: function handleDelayedValueChange() {
      if (!this.get('isDestroyed')) {
        this.set('value', this.get('delayedValue'));
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var property = this.get('property');
      if (property !== null) {
        this.set('value', Ember['default'].computed('form.model.' + property, {
          get: function get() {
            return this.get('form.model.' + property);
          },
          set: function set(key, value) {
            if (Ember['default'].isEmpty(value)) {
              value = null;
            }
            this.set('form.model.' + property, value);
            return value;
          }
        }));
      }
    }
  });

});