define('pzu-op/tests/components/ow-tokenfield.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components/ow-tokenfield.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'components/ow-tokenfield.js should pass jshint.');
  });

});