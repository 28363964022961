define('pzu-op/components/ow-secure', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'span',
    role: null,
    _auth: Ember['default'].inject.service('auth'),
    _isAuthorized: Ember['default'].computed("role", "_auth.roles", function () {
      return this.get('_auth.roles').contains(this.get('role'));
    })
  });

});