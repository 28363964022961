define('pzu-op/utils/is-in-viewport', ['exports', 'ember'], function (exports, Ember) {

  'use strict';



  exports['default'] = isInViewport;
  var defaultTolerance = {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10
  };
  function isInViewport() {
    var boundingClientRect = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
    var height = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
    var width = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
    var tolerance = arguments.length <= 3 || arguments[3] === undefined ? defaultTolerance : arguments[3];

    Ember['default'].assert('boundingClientRect can not be null', boundingClientRect);
    Ember['default'].assert('height can not be null', height);
    Ember['default'].assert('width can not be null', width);
    Ember['default'].assert('tolerance can not be null', tolerance);

    return boundingClientRect.top + tolerance.top >= 0 && boundingClientRect.left + tolerance.left >= 0 && boundingClientRect.bottom - tolerance.bottom <= height && boundingClientRect.right - tolerance.right <= width;
  }

});