define('pzu-op/tests/controllers/error.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/error.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'controllers/error.js should pass jshint.');
  });

});