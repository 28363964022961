define('pzu-op/components/af-date-group', ['exports', 'ember', 'pzu-op/components/ow-group'], function (exports, Ember, OwGroup) {

  'use strict';

  exports['default'] = OwGroup['default'].extend({
    date: null,

    init: function init() {
      this._super.apply(this, arguments);
      var property = this.get('property');
      if (property) {
        Ember['default'].defineProperty(this, 'date', Ember['default'].computed.alias('form.model.' + property));
      }
    }
  });

});