define('pzu-op/components/ow-form', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'form',

    model: null,
    errors: Ember['default'].computed.alias('model.errors'),

    submit: function submit(event) {
      event.preventDefault();
      this.sendAction();
    }
  });

});