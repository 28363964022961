define('pzu-op/components/ow-group', ['exports', 'ember', 'pzu-op/components/ow-form'], function (exports, Ember, OwForm) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    property: null,
    label: Ember['default'].computed('property', function () {
      return Ember['default'].String.capitalize(this.get('property'));
    }),
    labelClassNames: ['control-label'],
    errors: null,
    classNames: ['form-group'],
    classNameBindings: ['hasError', 'hasSuccess', 'hasWarning', 'hasFeedback'],

    showError: false,

    hasError: Ember['default'].computed('errors', 'showError', function () {
      return this.get('showError') === true && Ember['default'].isArray(this.get('errors')) && !Ember['default'].isEmpty(this.get('errors'));
    }),

    hasSuccess: Ember['default'].computed('errors', 'showError', function () {
      return this.get('showError') === true && Ember['default'].isArray(this.get('errors')) && Ember['default'].isEmpty(this.get('errors'));
    }),

    hasFeedback: Ember['default'].computed.notEmpty('glyphIcon'),

    glyphIcon: Ember['default'].computed('hasError', 'hasWarning', 'hasSuccess', function () {
      if (this.get('hasError') === true) {
        return 'glyphicon-remove';
      } else if (this.get('hasWarning') === true) {
        return 'glyphicon-warning-sign';
      } else if (this.get('hasSuccess') === true) {
        return 'glyphicon-ok';
      }
    }),

    fieldId: Ember['default'].computed('elementId', function () {
      return this.get('elementId') + '-field';
    }),

    form: Ember['default'].computed(function () {
      return this.nearestOfType(OwForm['default']);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var property = this.get('property');
      if (property !== null) {
        if (this.get('form.errors.' + property + '.content') !== undefined) {
          this.set('errors', Ember['default'].computed.alias('form.errors.' + property + '.content'));
        } else {
          this.set('errors', Ember['default'].computed.alias('form.errors.' + property));
        }
      }
    },

    actions: {
      showError: function showError() {
        this.set('showError', true);
      }
    }
  });

});