define('pzu-op/mixins/role-checks', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    auth: Ember['default'].inject.service(),
    isAnonymous: Ember['default'].computed("auth.roles", function () {
      return this.get('auth.roles').contains('ANONYMOUS');
    }),
    isUser: Ember['default'].computed("auth.roles", function () {
      return this.get('auth.roles').contains('USER');
    }),
    isAdmin: Ember['default'].computed("auth.roles", function () {
      return this.get('auth.roles').contains('ADMIN');
    })
  });

});