define('pzu-op/components/in-viewport-trigger', ['exports', 'ember', 'pzu-op/utils/is-in-viewport'], function (exports, Ember, isInViewport) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    eventName: Ember['default'].computed('elementId', function () {
      return 'scroll.scrollable.' + this.get('elementId');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var $window = this.$(window);
      var that = this;

      $window.on(this.get('eventName'), function () {
        Ember['default'].run.once(that, '_maybeTriggerAction');
      });
      Ember['default'].run.once(that, '_maybeTriggerAction');
    },

    willDestroyElement: function willDestroyElement() {
      this.$(window).off(this.get('eventName'));
    },

    _maybeTriggerAction: function _maybeTriggerAction() {
      var $window = this.$(window);
      var isVisibleNow = isInViewport['default'](this.get('element').getBoundingClientRect(), $window.height(), $window.width());

      if (isVisibleNow) {
        this.sendAction();
      }
    }
  });

});