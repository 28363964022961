define('pzu-op/helpers/moment', ['exports', 'ember', 'pzu-op/helpers/moment-format'], function (exports, Ember, Helper) {

  'use strict';

  exports['default'] = Helper['default'].extend({
    compute: function compute() {
      Ember['default'].deprecate('ember-moment: `moment` helper has been renamed to `moment-format`');
      return this._super.apply(this, arguments);
    }
  });

});