define('pzu-op/routes/authorized', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    auth: Ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this.get('auth.userPromiseObject').then(function (user) {
        "use strict";
        if (user === null) {
          _this.controllerFor('login').set('waitingTransition', transition);
          _this.transitionTo('login');
        }
      });
    }
  });

});