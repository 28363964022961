define('pzu-op/tests/unit/mixins/logged-in-route-test', ['ember', 'pzu-op/mixins/logged-in-route', 'qunit'], function (Ember, LoggedInRouteMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | logged in route');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var LoggedInRouteObject = Ember['default'].Object.extend(LoggedInRouteMixin['default']);
    var subject = LoggedInRouteObject.create();
    assert.ok(subject);
  });

});