define('pzu-op/tests/unit/mixins/role-checks-test', ['ember', 'pzu-op/mixins/role-checks', 'qunit'], function (Ember, RoleChecksMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | role checks');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var RoleChecksObject = Ember['default'].Object.extend(RoleChecksMixin['default']);
    var subject = RoleChecksObject.create();
    assert.ok(subject);
  });

});