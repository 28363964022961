define('pzu-op/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    auth: Ember['default'].inject.service(),
    isLoggedIn: Ember['default'].computed.alias('auth.isLoggedIn'),
    user: Ember['default'].computed.alias('auth.user'),

    errorId: null,

    _errorController: Ember['default'].inject.controller('error'),

    _showErrorOverlay: Ember['default'].computed('errorId', '_errorController.model', function () {
      if (this.get('_errorController.model') !== null) {
        var id = this.get('_errorController.model').errors[0].id;
        return this.get('errorId') !== null && this.get('errorId') !== id;
      }

      return this.get('errorId') !== null;
    }),

    actions: {
      logout: function logout() {
        var _this = this;

        this.get('auth').logout().then(function () {
          _this.send('refreshAllApp');
        });
      }
    }
  });

});