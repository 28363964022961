define('pzu-op/services/auth', ['exports', 'ember', 'ember-data', 'pzu-op/config/environment'], function (exports, Ember, DS, config) {

  'use strict';

  var AuthStatus = {
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT',
    PASSWORD_CHANGE_REQUIRED: 'PASSWORD_CHANGE_REQUIRED'
  };

  var ChangePasswordResult = {
    INVALID_TOKEN: 'INVALID_TOKEN',
    PASSWORDS_NOT_EQUAL: 'PASSWORDS_NOT_EQUAL',
    INVALID_PASSWORD: 'INVALID_PASSWORD',
    SAME_PASSWORD: 'SAME_PASSWORD',
    SUCCESS: 'SUCCESS'
  };

  var IsTokenValidResult = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    USED: 'USED',
    EXPIRED: 'EXPIRED'
  };

  exports['default'] = Ember['default'].Service.extend({
    store: Ember['default'].inject.service(),

    isLoggedIn: Ember['default'].computed.notEmpty('user'),

    roles: Ember['default'].computed('_roles', '_roles.isSettled', function () {
      var roles = this.get('_roles');
      if (roles.get('isSettled')) {
        var prefixLength = "ROLE_".length;
        return roles.get('content').map(function (role) {
          return role.substring(prefixLength);
        });
      }
      return Ember['default'].A();
    }),

    user: Ember['default'].computed("userPromiseObject.isSettled", {
      get: function get() {
        var user = this.get('userPromiseObject');
        if (!user.get('isSettled')) {
          return null;
        }

        return user.get('content');
      }
    }),

    _userIdPromise: Ember['default'].computed({
      get: function get() {
        return Ember['default'].RSVP.Promise.resolve(Ember['default'].$.getJSON(config['default'].apiPath + '/current-user-id').then(function (response) {
          return response.id;
        }));
      },
      set: function set(_, value) {
        if (value instanceof Ember['default'].RSVP.Promise) {
          return value;
        }
        return Ember['default'].RSVP.Promise.resolve(value);
      }
    }),

    authStatus: Ember['default'].computed({
      get: function get() {
        return Ember['default'].$.getJSON(config['default'].apiPath + '/auth-status');
      },
      set: function set(_, value) {
        if (value instanceof Ember['default'].RSVP.Promise) {
          return value;
        }
        return Ember['default'].RSVP.Promise.resolve(value);
      }
    }),

    userPromiseObject: Ember['default'].computed('_userIdPromise', {
      get: function get() {
        var that = this;

        var promise = this.get('_userIdPromise').then(function (userId) {
          if (userId == null) {
            return null;
          }
          return that.get('store').find('userAccount', userId);
        });

        return DS['default'].PromiseObject.create({
          promise: promise
        });
      }
    }),

    _roles: Ember['default'].computed('user', function () {
      return DS['default'].PromiseArray.create({
        promise: Ember['default'].$.getJSON(config['default'].apiPath + '/authorities')
      });
    }),

    login: function login(_login, password) {
      var formData = { login: _login, password: password };
      var loginPromise = Ember['default'].$.ajax({
        url: config['default'].apiPath + '/login',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(formData),
        processData: false
      });

      var userIdPromise = loginPromise.then(function (response) {
        return response.userId;
      });
      this.set('_userIdPromise', userIdPromise);

      var authStatusPromise = loginPromise.then(function (response) {
        return response.authStatus;
      });
      this.set('authStatus', authStatusPromise);

      return this.get('userPromiseObject').then(function (user) {
        return user !== null;
      });
    },

    changePassword: function changePassword(token, password, repeatedPassword) {
      var formData = { token: token, password: password, repeatedPassword: repeatedPassword };
      var changePasswordPromise = Ember['default'].$.ajax({
        url: config['default'].apiPath + '/change-password',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(formData),
        processData: false
      }).then(function (response) {
        return response;
      });

      var authStatusPromise = changePasswordPromise.then(function () {
        return Ember['default'].$.getJSON(config['default'].apiPath + '/auth-status');
      });
      this.set('authStatus', authStatusPromise);

      return changePasswordPromise;
    },

    logout: function logout() {
      if (!this.get('isLoggedIn')) {
        return;
      }
      var logoutPromise = Ember['default'].$.ajax({
        url: config['default'].apiPath + '/logout',
        type: 'POST'
      });

      var userIdPromise = logoutPromise.then(function () {
        return null;
      });
      this.set('_userIdPromise', userIdPromise);

      var authStatusPromise = logoutPromise.then(function () {
        return AuthStatus.LOGGED_OUT;
      });
      this.set('authStatus', authStatusPromise);

      return userIdPromise;
    },

    forgottenPassword: function forgottenPassword(email) {
      var formData = { email: email };
      Ember['default'].$.ajax({
        url: config['default'].apiPath + '/forgotten-password',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(formData),
        processData: false
      });
    }
  });

  exports.AuthStatus = AuthStatus;
  exports.ChangePasswordResult = ChangePasswordResult;
  exports.IsTokenValidResult = IsTokenValidResult;

});