define('pzu-op/tests/routes/application.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/application.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'routes/application.js should pass jshint.');
  });

});