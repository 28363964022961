define('pzu-op/routes/login', ['exports', 'ember', 'pzu-op/mixins/anonymous-route'], function (exports, Ember, AnonymousRoute) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AnonymousRoute['default'], {
    actions: {
      didTransition: function didTransition() {
        var fromUrl = this.get('router.url');
        if (fromUrl.indexOf('/login') !== 0 && fromUrl.indexOf('/change-password')) {
          this.set('controller.fromUrl', fromUrl);
        }
      }
    }
  });

});