define('pzu-op/validators/url', ['exports', 'ember-validations/validators/base', 'ember'], function (exports, Base, Ember) {

  'use strict';

  exports['default'] = Base['default'].extend({
    call: function call() {
      var value = this.model.get(this.property);
      var regex = /^[A-Za-z]+:\/{0,3}[\w-]+(\.[\w-]+)+\/?.*$/;
      if (!Ember['default'].isBlank(value) && !value.match(regex)) {
        this.errors.pushObject("invalid URL");
      }
    }
  });

});