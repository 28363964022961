define('pzu-op/components/ow-tokenfield', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['ow-tokenfield', 'clearfix'],
    tokens: null,
    maxSize: null,
    editable: true,
    addingEnabled: true,
    removingEnabled: true,

    _selectedTokenName: null,

    _maxSizeReached: Ember['default'].computed('tokens.length', 'maxSize', function () {
      var maxSize = this.get('maxSize');
      return maxSize !== null && this.get('tokens.length') >= maxSize;
    }),

    _canAddToken: Ember['default'].computed('_maxSizeReached', 'addingEnabled', function () {
      return this.get('addingEnabled') && !this.get('_maxSizeReached');
    }),

    actions: {
      add: function add(tokenName) {
        if (!Ember['default'].isEmpty(this.get('_selectedTokenName'))) {
          this.sendAction('addAction', tokenName);
          this.set('_selectedTokenName', '');
        }
      },

      remove: function remove(token) {
        this.sendAction('removeAction', token);
      }
    },
    didInsertElement: function didInsertElement() {
      this.$(".tt-input").focus();
    }
  });

});