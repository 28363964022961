define('pzu-op/components/purchase/filter-form', ['exports', 'ember', 'pzu-op/config/environment'], function (exports, Ember, config) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    /**
     * Model aktualnego filtra
     */
    filter: null,
    dateFormat: config['default'].dateFormat,

    /**
     * Dostępne statusy transakcji (jako tablica obiektów modelu payu-transaction-status)
     */
    payuTransactionStatuses: null,
    payuTransactionStatusOptions: Ember['default'].computed.map('payuTransactionStatuses', function (payuTransactionStatus) {
      return Ember['default'].Object.create({
        id: payuTransactionStatus.get('code'),
        name: payuTransactionStatus.get('description')
      });
    }),

    filterProperties: Ember['default'].A(['payuTransactionStatusCode', 'entrySubmissionDateFrom', 'entrySubmissionDateTo'], 'transRecvFrom', 'transRecvTo')
  });

});