define('pzu-op/components/ow-select-group', ['exports', 'ember', 'pzu-op/components/ow-group'], function (exports, Ember, OwGroup) {

  'use strict';

  exports['default'] = OwGroup['default'].extend({
    glyphIcon: false,

    selection: null,
    content: null,
    selectionIsModel: true,

    init: function init() {
      this._super.apply(this, arguments);
      var property = this.get('property');
      if (property) {
        //Ember.Binding.to('selection').from('form.model.' + property).connect(this);
        Ember['default'].defineProperty(this, 'selection', Ember['default'].computed.alias('form.model.' + property));

        //this.set('selection', Ember.computed.alias('form.model.' + property));
      }
    }
  });

});