define('pzu-op/tests/unit/mixins/tag-autocomplete-test', ['ember', 'pzu-op/mixins/tag-autocomplete', 'qunit'], function (Ember, TagAutocompleteMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | tag autocomplete');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var TagAutocompleteObject = Ember['default'].Object.extend(TagAutocompleteMixin['default']);
    var subject = TagAutocompleteObject.create();
    assert.ok(subject);
  });

});