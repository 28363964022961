define('pzu-op/routes/purchases', ['exports', 'ember', 'pzu-op/config/environment', 'pzu-op/mixins/logged-in-route'], function (exports, Ember, config, LoggedInRoute) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(LoggedInRoute['default'], {
    pageSize: Ember['default'].computed.alias('controller.pageSize.id'),
    pageNumber: Ember['default'].computed.alias('controller.pageNumber'),
    sortVariable: Ember['default'].computed.alias('controller.sortVariable'),

    queryParams: {
      payuTransactionStatusCode: {
        refreshModel: false,
        replace: true,
        queryName: "payuTransactionStatusCode"
      },
      entrySubmissionDateFrom: {
        refreshModel: false,
        replace: true,
        queryName: "entrySubmissionDateFrom"
      },
      entrySubmissionDateTo: {
        refreshModel: false,
        replace: true,
        queryName: "entrySubmissionDateTo"
      },
      transRecvFrom: {
        refreshModel: false,
        replace: true,
        queryName: "transRecvFrom"
      },
      transRecvTo: {
        refreshModel: false,
        replace: true,
        queryName: "transRecvTo"
      },
      sortVariable: {
        refreshModel: true,
        replace: true,
        queryName: "sortVariable"
      }
    },

    model: function model() {
      return Ember['default'].RSVP.hash({
        columns: Ember['default'].$.getJSON(config['default'].apiPath + '/purchaseColumns').then(function (data) {
          return data;
        }),
        rows: Ember['default'].$.getJSON(config['default'].apiPath + '/purchaseRows' + this.getCurrentParams()).then(function (data) {
          return data;
        }),
        rowCount: Ember['default'].$.getJSON(config['default'].apiPath + '/purchaseRows/count' + this.getCurrentParams()).then(function (data) {
          return parseInt(data);
        })
      });
    },

    getCurrentParams: function getCurrentParams() {
      var queryParams = this.get('queryParams');

      var allParams = this.paramsFor(this.get('routeName'));
      var keys = Object.keys(allParams).filter(function (key) {
        return !Ember['default'].isBlank(allParams[key]) && !Ember['default'].isBlank(queryParams[key].queryName);
      });

      var params = '?page[number]=' + (this.get('pageNumber') || 0);
      params += '&page[size]=' + (this.get('pageSize') || config['default'].defaultPageSize);

      keys.forEach(function (key) {
        params += '&query[' + queryParams[key].queryName + ']=' + allParams[key];
      });
      if (this.get('sortVariable') !== null && this.get('sortVariable') !== undefined) {
        params += '&sort=' + this.get('sortVariable');
      }
      return params;
    },

    actions: {
      download: function download() {
        var params = this.getCurrentParams();
        window.open(config['default'].apiPath + '/purchaseReport' + params, '_blank');
      },
      sortBy: function sortBy(sortVariable) {
        var oldSort = this.get('sortVariable');
        var newSort = sortVariable;

        //brak sortowania lub zmiana kolumny na inną
        if (oldSort === null || oldSort === undefined || oldSort.indexOf(newSort) < 0) {
          newSort = '-' + newSort;
        } else {
          //obsługa sortowania asc i desc dla ponownego wybrania tej samej kolumny
          if (oldSort.charAt(0) !== '-') {
            newSort = '-' + newSort;
          }
        }
        this.set('sortVariable', newSort);
        this.set('pageNumber', 0);
        this.refresh();
      }
    }

  });

});