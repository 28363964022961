define('pzu-op/controllers/change-password', ['exports', 'ember', 'ember-data', 'pzu-op/config/environment', 'pzu-op/services/auth'], function (exports, Ember, DS, config, auth) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    password: null,
    repeatedPassword: null,
    errorMessage: null,

    auth: Ember['default'].inject.service(),

    token: null,

    tokenValidResult: Ember['default'].computed('token', function () {
      if (!this.get('token') || 0 === this.get('token').length) {
        return DS['default'].PromiseObject.create({
          promise: Ember['default'].RSVP.resolve(auth.IsTokenValidResult.VALID)
        });
      }

      return DS['default'].PromiseObject.create({
        promise: Ember['default'].$.getJSON(config['default'].apiPath + '/is-token-valid?token=' + this.get('token'))
      });
    }),

    isTokenValid: Ember['default'].computed('tokenValidResult', function () {
      return this._isToken(auth.IsTokenValidResult.VALID);
    }),

    isTokenExpired: Ember['default'].computed('tokenValidResult', function () {
      return this._isToken(auth.IsTokenValidResult.EXPIRED);
    }),

    isTokenUsed: Ember['default'].computed('tokenValidResult', function () {
      return this._isToken(auth.IsTokenValidResult.USED);
    }),

    _isToken: function _isToken( /** IsTokenValidResult */expectedResult) {
      var promise = this.get('tokenValidResult').then(function (result) {
        return expectedResult === result;
      });
      return DS['default'].PromiseObject.create({
        promise: promise
      });
    },

    reset: function reset() {
      this.setProperties({
        password: null,
        repeatedPassword: null,
        errorMessage: null
      });
    },

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var password = this.get('password');
        var repeatedPassword = this.get('repeatedPassword');
        var token = this.get('token');

        this.get('auth').changePassword(token, password, repeatedPassword).then(function (changePasswordResult) {
          switch (changePasswordResult) {
            case auth.ChangePasswordResult.PASSWORDS_NOT_EQUAL:
              _this.set('errorMessage', 'Wprowadzone hasła są różne.');
              break;
            case auth.ChangePasswordResult.INVALID_PASSWORD:
              _this.set('errorMessage', 'Hasło powinno zawierać co najmniej 8 znaków, w tym jedną literę wielką, jedną ' + 'cyfrę lub jeden znak specjalny.');
              break;
            case auth.ChangePasswordResult.SAME_PASSWORD:
              _this.set('errorMessage', 'Wprowadzone hasło jest takie samo jak obecne.');
              break;
            case auth.ChangePasswordResult.INVALID_TOKEN:
              _this.send('refreshAllApp', false);
              break;
            case auth.ChangePasswordResult.SUCCESS:
              _this.set('errorMessage', null);

              _this.set('waitingTransition', null);
              _this.set('fromUrl', null);
              _this.set('token', null);

              _this.send('transitToIndex');
              break;
            default:
              throw "Incorrect change password result";
          }
        });
      }
    }
  });

});