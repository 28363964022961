define('pzu-op/initializers/validation-config', ['exports'], function (exports) {

  'use strict';

  //import Ember from 'ember';

  //import BookModel from '../models/book';
  //import AuthorModel from '../models/author';
  //import PublisherModel from '../models/publisher';
  //import RateModel from '../models/rate';

  /*function prepareValidationConfig(config) {
    let result = {};
    for (var fieldName in config) {
      var fieldValidators = {};
      var fieldConfig = config[fieldName];

      let isInteger = (fieldConfig.type === 'Integer' || fieldConfig.type === 'Long');

      if (fieldConfig.min || fieldConfig.max) {
        fieldValidators.numericality = {
          onlyInteger: isInteger || undefined,
          greaterThanOrEqualTo: fieldConfig.min || undefined,
          lessThanOrEqualTo: fieldConfig.max || undefined,
          allowBlank: true
        };
      }

      if (fieldConfig.size) {
        fieldValidators.length = {
          minimum: fieldConfig.size.min || undefined,
          maximum: fieldConfig.size.max || undefined,
          allowBlank: true
        };
      }

      if (fieldConfig.pattern) {
        var regexp = new RegExp(fieldConfig.pattern);
        fieldValidators.format = {
          with: regexp,
          allowBlank: true
        };
      }

      if (fieldConfig.url) {
        fieldValidators.url = true;
      }

      *if (fieldConfig.notNull) {
        fieldValidators.presence = true;
      }*

      if (fieldConfig.isbn) {
        fieldValidators.isbn = true;
      }

      if (Object.keys(fieldValidators).length > 0) {
        //console.log(JSON.stringify(fieldValidators));
        result[fieldName] = fieldValidators;
      }
    }

    return result;
  }*/

  exports['default'] = {
    name: "validation-config",

    initialize: function initialize() {}
    //  initialize(container, application) {
    //    application.deferReadiness();
    //    Ember.$.ajax({
    //      url: '/api/validationConfig',
    //      success(response) {
    //        for (var modelName in response) {
    //          let validations = prepareValidationConfig(response[modelName]);
    //          // FIXME: poprawka dla relacji
    //          /*console.log(modelName + ' ' + JSON.stringify(validations));
    //          if (modelName === 'book') {
    //            BookModel.reopen({validations: validations});
    //          } else if (modelName === 'author') {
    //            AuthorModel.reopen({validations: validations});
    //          } else if (modelName === 'publisher') {
    //            PublisherModel.reopen({validations: validations});
    //          } else if (modelName === 'rate') {
    //            RateModel.reopen({validations: validations});
    //          }*/
    //        }
    //        application.advanceReadiness();
    //      },
    //      error(reason) {
    ////        reject(reason);
    //      }
    //    });
    //  }
  };

});