define('pzu-op/tests/helpers/cond.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/cond.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(false, 'helpers/cond.js should pass jshint.\nhelpers/cond.js: line 7, col 26, Expected \'===\' and instead saw \'==\'.\nhelpers/cond.js: line 23, col 26, Expected \'!==\' and instead saw \'!=\'.\n\n2 errors');
  });

});