define('pzu-op/initializers/setup-pikaday-i18n', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = {
    name: 'setup-pikaday-i18n',
    initialize: function initialize(container, application) {
      var i18n = Ember['default'].Object.extend({
        previousMonth: 'Poprzedni miesiąc',
        nextMonth: 'Następny miesiąc',
        months: 'STYCZEŃ_LUTY_MARZEC_KWIECIEŃ_MAJ_CZERWIEC_LIPIEC_SIERPIEŃ_WRZESIEŃ_PAŹDZIERNIK_LISTOPAD_GRUDZIEŃ'.split('_'),
        weekdays: moment['default'].localeData('pl')._weekdays,
        weekdaysShort: moment['default'].localeData('pl')._weekdaysShort
      });

      container.register('pikaday-i18n:main', i18n, { singleton: true });
      application.inject('component:af-pikaday-input', 'i18n', 'pikaday-i18n:main');
      application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
    }
  };

});