define('pzu-op/routes/change-password', ['exports', 'ember', 'pzu-op/services/auth'], function (exports, Ember, auth) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    auth: Ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super();
      return this.get('auth.authStatus').then(function (authStatus) {
        'use strict';
        if (authStatus !== auth.AuthStatus.PASSWORD_CHANGE_REQUIRED && !_this.paramsFor(_this.get('routeName')).token) {
          _this.transitionTo('index');
        }
      });
    },

    queryParams: {
      token: {
        refreshModel: false,
        replace: true,
        queryName: "token"
      }
    }

  });

});