define('pzu-op/controllers/purchases', ['exports', 'ember', 'pzu-op/config/environment'], function (exports, Ember, config) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    /**
     * Dostępne rozmiary stron
     */
    pageSizes: [{ id: '10', name: '10' }, { id: '20', name: '20' }, { id: '50', name: '50' }],
    /**
     * Domyślny rozmiar strony
     */
    pageSize: { id: config['default'].defaultPageSize, name: config['default'].defaultPageSize },
    /**
     * Aktualny numer strony
     */
    pageNumber: 0,
    /**
    * Kolumna po której sortujemy, - na początku to przełącznik asc/desc
    */
    sortVariable: null,

    /**
     * Dolna granica wyświetlanych rekordów
     */
    lowerBound: Ember['default'].computed('pageSize', 'pageNumber', 'model.rowCount', function () {
      if (this.get('model.rowCount') > 0) {
        return this.get('pageSize').id * this.get('pageNumber') + 1;
      } else {
        return 0;
      }
    }),

    /**
     * Górna granica wyświetlanych rekordów
     */
    upperBound: Ember['default'].computed('pageSize', 'pageNumber', 'model.rowCount', function () {
      var pageSize = parseInt(this.get('pageSize').id);
      return Math.min(pageSize * this.get('pageNumber') + pageSize, this.get('model.rowCount'));
    }),

    isPrevPageDisabled: Ember['default'].computed('pageNumber', function () {
      return this.get('pageNumber') === 0;
    }),

    isNextPageDisabled: Ember['default'].computed('pageNumber', 'model.rowCount', function () {
      return this.get('upperBound') >= this.get('model.rowCount');
    }),

    /**
     * Obiekt modelu przekazywany do komponentu filter-form
     */
    filterModel: {
      payuTransactionStatusCode: null,
      entrySubmissionDateFrom: null,
      entrySubmissionDateTo: null,
      transRecvFrom: null,
      transRecvTo: null
    },

    /**
     * Dostępne statusy transakcji. Wartość przekazywana do komponentu filter-form
     */
    payuTransactionStatuses: Ember['default'].computed(function () {
      return this.store.peekAll('payu-transaction-status');
    }),

    /**
     * Poniższe deklaracje uwidaczniają pola modelu edytowane przez komponentu filter-form
     */
    payuTransactionStatusCode: Ember['default'].computed.alias('filterModel.payuTransactionStatusCode'),
    entrySubmissionDateFrom: Ember['default'].computed.alias('filterModel.entrySubmissionDateFrom'),
    entrySubmissionDateTo: Ember['default'].computed.alias('filterModel.entrySubmissionDateTo'),
    transRecvFrom: Ember['default'].computed.alias('filterModel.transRecvFrom'),
    transRecvTo: Ember['default'].computed.alias('filterModel.transRecvTo'),

    actions: {
      pageSizeChanged: function pageSizeChanged() {
        this.set('pageNumber', 0);
        this.send('refresh');
      },
      /**
       * Przeładowanie modelu
       */
      refresh: function refresh() {
        this.get('target.router').refresh();
      },
      search: function search() {
        this.set('pageNumber', 0);
        this.send('refresh');
      },
      prevPage: function prevPage() {
        var currentPageNumber = this.get('pageNumber');
        if (currentPageNumber > 0) {
          this.set('pageNumber', currentPageNumber - 1);
        }
        this.send('refresh');
      },
      nextPage: function nextPage() {
        this.set('pageNumber', this.get('pageNumber') + 1);
        this.send('refresh');
      }
    }
  });

});