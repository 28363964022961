define('pzu-op/routes/index', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        auth: Ember['default'].inject.service(),
        beforeModel: function beforeModel() {
            this.transitionTo('purchases');
        }
    });

});