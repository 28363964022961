define('pzu-op/tests/unit/mixins/anonymous-test', ['ember', 'pzu-op/mixins/anonymous', 'qunit'], function (Ember, AnonymousMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | anonymous');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var AnonymousObject = Ember['default'].Object.extend(AnonymousMixin['default']);
    var subject = AnonymousObject.create();
    assert.ok(subject);
  });

});