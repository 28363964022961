define('pzu-op/components/ow-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    //tagName: 'select',
    //classNames: ['form-control'],
    //attributeBindings: ['multiple'],

    content: Ember['default'].A(),
    selectionIsModel: true,
    modelValueAttribute: 'id',
    classes: 'form-control',
    selection: null,
    value: null,
    multiple: false,
    nullOption: false,
    nullOptionName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var multiple = this.get('multiple');
      var selectionIsModel = this.get('selectionIsModel');

      if (selectionIsModel) {
        var modelValueAttribute = this.get('modelValueAttribute');
        if (multiple) {
          this.set('value', Ember['default'].computed.mapBy('selection', modelValueAttribute));
        } else {
          this.set('value', Ember['default'].computed.reads('selection.' + modelValueAttribute));
        }
      } else {
        this.set('value', Ember['default'].computed.reads('selection'));
      }

      if (this.get('selection') === null) {
        this.set('nullOption', true);
      }
    },

    updateSelectionFromProperty: function updateSelectionFromProperty() {
      var multiple = this.get('multiple');
      if (multiple) {
        var values = this.get('value');
        this.$('select option').each(function (index, element) {
          element.selected = values.indexOf(element.value) !== -1;
        });
      } else {
        var value = this.get('value');
        if (value == null) {
          value = '';
        }
        this.$('select').prop('value', value);
      }
    },

    updateSelectionFromElement: function updateSelectionFromElement() {
      var content = this.get('content');
      var multiple = this.get('multiple');
      var selectionIsModel = this.get('selectionIsModel');
      var modelValueAttribute = this.get('modelValueAttribute');
      var selection = Ember['default'].A();
      this.$('select option').each(function (index, element) {
        if (element.selected) {
          var value = element.value;
          if (Ember['default'].isEmpty(value)) {
            value = null;
          }

          if (selectionIsModel) {
            var item = content.findBy(modelValueAttribute, value);
            selection.push(item);
          } else {
            selection.push(value);
          }
        }
      });
      if (!multiple) {
        selection = Ember['default'].isEmpty(selection) ? null : selection.get('firstObject');
      }
      this.set('selection', selection);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateSelectionFromProperty();
    },

    selectionObserver: Ember['default'].observer('selection', function () {
      this.updateSelectionFromProperty();
    }),

    actions: {
      selectionChanged: function selectionChanged() {
        this.updateSelectionFromElement();
        this.sendAction('selectionChanged', this.get('selection'));
      },

      focusOut: function focusOut() {
        this.sendAction('focusOut');
      }
    }
  });

});